import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  main: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  avatar: {
    margin: theme.spacing.unit * 1,
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%",
    marginTop: theme.spacing.unit * 1
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  }
});

function ContactForm(props) {
  const { classes, onSubmit, updateFields, contact, title } = props;

  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5">
          {title}
        </Typography>
        <form className={classes.form} onSubmit={onSubmit}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="name">Nimi</InputLabel>
            <Input id="name" name="name" onChange={e => updateFields(e, 'name')} value={contact.name} />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email">Sähköpostiosoite</InputLabel>
            <Input id="email" name="email" autoComplete="email" onChange={e => updateFields(e, 'email')} value={contact.email} />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="phone">Puhelin numero</InputLabel>
            <Input id="phone" phone="phone" onChange={e => updateFields(e, 'phone')} value={contact.phone} />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="titles">Tittelit</InputLabel>
            <Input id="titles" onChange={e => updateFields(e, 'titles')} value={contact.titles} />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="areaOfExpertise">Erityisosaaminen</InputLabel>
            <Input id="areaOfExpertise" onChange={e => updateFields(e, 'areaOfExpertise')} value={contact.areaOfExpertise} />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="imageUrl">Kuvan URL</InputLabel>
            <Input id="imageUrl" onChange={e => updateFields(e, 'imageUrl')} value={contact.imageUrl} />
          </FormControl>
          {contact.imageUrl ? <img alt={'image of '+contact.name} src={contact.imageUrl} /> : ''}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Tallenna
          </Button>
        </form>
      </Paper>
    </main>
  );
}


export default withStyles(styles)(ContactForm);
