import app from 'firebase/app';
import Config from '../../config';
import 'firebase/auth';
import 'firebase/database'

class Firebase {
  constructor() {
    app.initializeApp(Config.firebase);

    this.auth = app.auth();
    this.db = app.database();
  }

  // *** AUTH API ***

  doSignInWithEmailAndPassword(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  doSignOut() {
    this.auth.signOut();
  }
}

export default Firebase;
