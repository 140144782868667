import { Base64 } from 'js-base64';

const botUrl = 'https://ptcservices.now.sh?preview=';

const botPreview = (previewContent) => {
    const content = Base64.encode(JSON.stringify(previewContent));
    window.open(`${botUrl}${content}`);
};

export default botPreview;
