import React, { Component } from "react";
import { withRouter } from "react-router";
import { withFirebase } from '../Firebase';
import { compose } from 'recompose';

import * as ROUTES from '../../constants/routes';
import LoginForm from "./LoginForm";

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null
};

class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onSubmit(event) {
    event.preventDefault();

    const { email, password } = this.state;
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.GRAPH)
      })
      .catch(error => {
        this.setState({ error });
      });
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    return <LoginForm onSubmit={this.onSubmit} onChange={this.onChange} />;
  }
}

const LoginView = compose(
  withRouter,
  withFirebase
)(LoginPage);

export default LoginView;
