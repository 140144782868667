import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import {
  GraphView, // required
} from 'react-digraph';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';

import uuid4 from 'uuid4';

import Config from '../../config';

import PreviewService from '../../services/PreviewService';
import BuilderForm from './BuilderForm';

import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

const styles = {
  graph: {
    height: window.innerHeight - 70,
    width: window.innerWidth - 200,
    position: 'relative',
    left: '200px'
  }
}

const NODE_KEY = 'id'       // Allows D3 to correctly update DOM

class Graph extends Component {

  constructor(props) {
    super(props);

    this.state = {
      contacts: {},
      graphs: {},
      graph: {
        uuid: uuid4(),
        answerId: 1,
        edges: [],
        linkId: 1,
        nodes: [],
        questionId: 1,
        treeName: '',
        phase: '',
        contact: ''
      },
      answer: {
        en: {
          title: ''
        },
        fi: {
          title: ''
        }
      },
      link: {
        en: {
          title: ''
        },
        fi: {
          title: ''
        },
        link: ''
      },
      question: {
        en: {
          title: '',
          detail: ''
        },
        fi: {
          title: '',
          detail: ''
        }
      },
      selected: {}
    }

    this.db = this.props.firebase.db;

    this.onCreateNode = this.onCreateNode.bind(this)
    this.onSelectNode = this.onSelectNode.bind(this)
    this.onDeleteNode = this.onDeleteNode.bind(this)
    this.onCreateEdge = this.onCreateEdge.bind(this)
    this.onSelectEdge = this.onSelectEdge.bind(this)
    this.onDeleteEdge = this.onDeleteEdge.bind(this)
    this.onUpdateNode = this.onUpdateNode.bind(this)
    this.onBackgroundClick = this.onBackgroundClick.bind(this)

    this.printGraph = this.printGraph.bind(this)
    this.handleTreeNameChange = this.handleTreeNameChange.bind(this)
    this.clearGraph = this.clearGraph.bind(this)
    this.saveGraph = this.saveGraph.bind(this)
    this.openGraph = this.openGraph.bind(this);
    this.saveFields = this.saveFields.bind(this);
    this.clearFields = this.clearFields.bind(this);

    this.getGraphs = this.getGraphs.bind(this);
    this.getContacts = this.getContacts.bind(this);
    this.formOnChange = this.formOnChange.bind(this);
  }

  componentDidMount() {
    if (this.state.graph.treeName === '') {
      this.db.ref('/').once('value').then((snap) => {
        this.setState({ contacts: snap.val()['contacts'], graphs: snap.val()['graphs'], graph: { ...snap.val()['graphs']['contract']['5bc4b4cf-3409-4c55-a31f-ae569fc52703'] } })
      })
    }

    // TODO: Get contacts from firebase

    // TODO: Create Select for tree to link to
    // - Get all trees from firebase
    // - Create list of linkable trees
    // - Create Select from linkable trees list
    // - Save cnoice to link
    // - Empty Select-link

    // TODO: Load and edit trees from firebase

  }

  openGraph(selectedTree) {

    console.log('open graph:', selectedTree)
    for (let phase in this.state.graphs) {
      for (let tree in this.state.graphs[phase]) {
        if (this.state.graphs[phase][tree]['uuid'] === selectedTree) {
          console.log(this.state.graphs[phase][tree])
          this.setState({ ...this.state, graph: this.state.graphs[phase][selectedTree] })
        }
      }
    }

  }

  getGraphs() {
    return Object.keys(this.state.graphs).map((phase, i) => {
      return Object.keys(this.state.graphs[phase]).map((tree, j) => {
        return <MenuItem key={this.state.graphs[phase][tree]['uuid']} value={this.state.graphs[phase][tree]['uuid']}>{this.state.graphs[phase][tree]['treeName']}</MenuItem>
      })
    });
  }

  getContacts() {
    return Object.keys(this.state.contacts).map((contact, i) => {
      return <MenuItem key={i} value={this.state.contacts[contact]['uuid']}>{this.state.contacts[contact].name}</MenuItem>
    });
  }

  saveGraph() {
    if (!this.state.graph.uuid) {
      this.setState({ graph: {...this.state.graph, uuid: uuid4() } })
    } else {
      this.db.ref(`graphs/${this.state.graph.phase}/${this.state.graph.uuid}`).set(this.state.graph)
    }
  }


  onSelectNode(selected) {
    this.setState({ selected })

  }

  onCreateNode(x, y) {
    const graph = this.state.graph
    let viewNode = {}
    let update = {}
    if (this.state.question.fi.title) {
      viewNode.detail = this.state.question
      viewNode.id = `${graph.uuid}-question-${graph.questionId++}`
      viewNode.title = this.state.question.fi.title
      viewNode.type = 'question'
    } else if (this.state.answer.fi.title) {
      viewNode.detail = this.state.answer
      viewNode.id = `${graph.uuid}-answer-${graph.answerId++}`
      viewNode.title = this.state.answer.fi.title
      viewNode.type = 'answer'
    } else if (this.state.link.fi.title) {
      viewNode.detail = this.state.link
      viewNode.id = `${graph.uuid}-link-${graph.linkId++}`
      viewNode.title = this.state.link.fi.title
      viewNode.type = 'link'
    } else {
      return
    }

    viewNode.x = x
    viewNode.y = y

    graph.nodes = [...graph.nodes, viewNode]
    update = { graph }
    update.question = {
      en: {
        title: '',
        detail: ''
      },
      fi: {
        title: '',
        detail: ''
      },
    }
    update.answer = {
      en: {
        title: ''
      },
      fi: {
        title: ''
      },
    }
    update.link = {
      en: {
        title: ''
      },
      fi: {
        title: ''
      },
      link: ''
    }

    this.setState(update)
  }

  onUpdateNode(e) {
    console.log('onUpdateNode', e)
    let update = this.state.graph;
    console.log('graph', update)



  }

  onBackgroundClick(e) {
    console.log('onBackgroundClick', e)
  }

  onDeleteNode(viewNode, nodeId, nodeArr) {
    const graph = this.state.graph
    const newEdges = graph.edges.filter((edge, i) => {
      return edge.source !== viewNode[NODE_KEY] && edge.target !== viewNode[NODE_KEY]
    })

    graph.nodes = nodeArr
    graph.edges = newEdges

    // Update the correct id counter
    switch(viewNode.type) {
      case 'question':
        graph.questionId--
        break
      case 'answer':
        graph.answerId--
        break
      default:
        graph.linkId--
    }

    this.setState({ graph, selected: null })
  }

  onSelectEdge(selected) {
    this.setState({ selected })
  }

  onCreateEdge(sourceNode, targetNode) {
    const graph = this.state.graph
    const type = sourceNode.x <= targetNode.x ? 'yes' : 'no'
    const viewEdge = {
      source: sourceNode[NODE_KEY],
      target: targetNode[NODE_KEY],
      type
    }

    if (viewEdge.source !== viewEdge.target && sourceNode.type !== 'answer') {
      graph.edges = graph.edges ? [...graph.edges, viewEdge] : [ viewEdge ]
      this.setState({
        graph
      })
    }
  }

  onSwapEdge(e) {
    console.log('onSwapEdge', e)
  }

  onDeleteEdge(viewEdge, edgeArr) {
    const graph = this.state.graph
    graph.edges = edgeArr
    this.setState({
      graph,
      selected: null
    })
  }

  botPreview = () => PreviewService(this.printGraph());

  printGraph() {
    const graph = this.state.graph
    let choiceValue = 0
    let steps = []
    graph.nodes.map((node, index) => {
      const edges = graph.edges.filter((edge, i) => {
        return edge.source === node.id
      })
      let options = edges.map((edge, i) => {
        return {
          value: choiceValue++,
          label: edge.type === 'yes' ? 'Kyllä' : 'Ei',
          trigger: edge.target,
        }

      })

      let step = {
        id: node.id,
        message: node.title,
      }

      // Case question
      if (node.type === 'question') {
        step['trigger'] = `${this.state.graph.uuid}-${node.id}-choices`
        steps.push(step)

        // Add choices step
        let choices = {
          id: `${this.state.graph.uuid}-${node.id}-choices`
        }

        // If the question has details, add details to the choices step and the option to select details
        if (node.detail) {
          choices['metadata'] = {
            parent: node.id,
            detail: node.detail
          }
          options.push({ value: choiceValue++, label: 'Selite', trigger: 'detail' })
        }

        choices['options'] = options
        steps.push(choices)

        // Case link to another tree
      } else if (node.type === 'link') {
        if(node.link) {
          step['trigger'] = `${node.link}`
        }
        steps.push(step)

        // Case answer
        // TODO: Choices to continue to another tree on the same subject or to change the subject
      } else {
        step['end'] = true
        steps.push(step)
      }

      return ''
    })

    return this.state.graph;
  }

  handleTreeNameChange(e) {
    // TODO: Change question and answers prefix from suunnittelu-* to the new tree id
    // const graph = this.state.graph
    // graph.nodes = graph.nodes.map((node) => {
    //   node.source = node.source
    // })
    this.setState({
      graph: {
        ...this.state.graph,
        treeName: e.target.value
      },
    })
  }

  clearGraph() {
    let update = {
      graph: {
        uuid: uuid4(),
        answerId: 1,
        edges: [],
        linkId: 1,
        nodes: [],
        questionId: 1,
        treeName: '',
        contact: '',
        phase: ''
      },
      answer: {
        en: {
          title: ''
        },
        fi: {
          title: ''
        }
      },
      link: {
        en: {
          title: ''
        },
        fi: {
          title: ''
        },
        link: ''
      },
      question: {
        en: {
          title: '',
          detail: ''
        },
        fi: {
          title: '',
          detail: ''
        }
      },
      selected: {},
    }

    this.setState(update)
  }

  saveFields(e, field) {
    const { value, name } = e.target
    console.log(value, name)

    let update = {
      question: {
        en: {
          title: field === 'question.en.title' ? e.target.value : this.state.question.en.title,
          detail: field === 'question.en.detail' ? e.target.value : this.state.question.en.detail,
        },
        fi: {
          title: field === 'question.fi.title' ? e.target.value : this.state.question.fi.title,
          detail: field === 'question.fi.detail' ? e.target.value : this.state.question.fi.detail,
        },
      },
      answer: {
        en: {
          title: field === 'answer.en.title' ? e.target.value : this.state.answer.en.title
        },
        fi: {
          title: field === 'answer.fi.title' ? e.target.value : this.state.answer.fi.title
        },
      },
      link: {
        en: {
          title: field === 'link.en.title' ? e.target.value : this.state.link.en.title
        },
        fi: {
          title: field === 'link.fi.title' ? e.target.value : this.state.link.fi.title
        },
        link: field === 'link.link' ? e.target.value : this.state.link.link
      }
    }

    this.setState(update)
  }

  clearFields() {
    let update = {
      question: {
        en: {
          title: '',
          detail: '',
        },
        fi: {
          title: '',
          detail: '',
        },
      },
      answer: {
        en: {
          title: ''
        },
        fi: {
          title: ''
        },
      },
      link: {
        en: {
          title: ''
        },
        fi: {
          title: ''
        },
        link: ''
      }
    }

    this.setState(update)
  }

  formOnChange(load) {
    this.setState(load)
  }

  /* Define custom graph editing methods here */

  logOut = () => this.props.firebase.doSignOut();

  render() {
    const nodes = this.state.graph.nodes;
    const edges = this.state.graph.edges || [];
    const selected = this.state.selected;

    const NodeTypes = Config.graph.NodeTypes;
    const NodeSubtypes = Config.graph.NodeSubtypes;
    const EdgeTypes = Config.graph.EdgeTypes;




    return (
      <div>
        <BuilderForm
          graph={this.state.graph}
          onChange={this.formOnChange}
          handleTreeNameChange={this.handleTreeNameChange}
          getContacts={this.getContacts}
          saveFields={this.saveFields}
          clearFields={this.clearFields}
          question={this.state.question}
          answer={this.state.answer}
          link={this.state.link}
          getGraphs={this.getGraphs}
          openGraph={this.openGraph}
          saveGraph={this.saveGraph}
          clearGraph={this.clearGraph}
          botPreview={this.botPreview}
          />
        <div id='graph' style={styles.graph}>
          <GraphView
            ref='GraphView'
            nodeKey={NODE_KEY}
            nodes={nodes}
            edges={edges}
            selected={selected}
            nodeTypes={NodeTypes}
            nodeSubtypes={NodeSubtypes}
            edgeTypes={EdgeTypes}
            onSelectNode={this.onSelectNode}
            onCreateNode={this.onCreateNode}
            onUpdateNode={this.onUpdateNode}
            onBackgroundClick={this.onBackgroundClick}
            onDeleteNode={this.onDeleteNode}
            onSelectEdge={this.onSelectEdge}
            onCreateEdge={this.onCreateEdge}
            onSwapEdge={this.onSwapEdge}
            onDeleteEdge={this.onDeleteEdge}
            layoutEngineType={'SnapToGrid'}
            />

          <Button variant="outlined" style={{position:'absolute', bottom: 20, right: 20}} onClick={this.logOut}>Logout</Button>

        </div>
      </div>
    );
  }

}

const authCondition = authUser => !!authUser;
const BuilderView = compose(
  withRouter,
  withFirebase
)(Graph)

export default withAuthorization(authCondition)(BuilderView);
