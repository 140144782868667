import React from 'react';

const Config = {
  firebase: {
    apiKey: "AIzaSyB9ud80sG03Sip6UnxBvY_x6faGQT6B6qE",
    authDomain: "ptcservices.firebaseapp.com",
    databaseURL: "https://ptcservices.firebaseio.com",
    projectId: "ptcservices",
    storageBucket: "ptcservices.appspot.com",
    messagingSenderId: "215848715485"
  },
  graph: {
    NodeTypes: {
      answer: { // required to show answer nodes
        typeText: "Vastaus",
        shapeId: "#answer", // relates to the type property of a node
        shape: (
          <symbol viewBox="0 0 100 100" id="answer" key="0">
            <circle cx="50" cy="50" r="45"></circle>
          </symbol>
        )
      },
      custom: { // required to show empty nodes
        typeText: "Custom",
        shapeId: "#custom", // relates to the type property of a node
        shape: (
          <symbol viewBox="0 0 50 25" id="custom" key="0">
            <ellipse cx="50" cy="25" rx="50" ry="25"></ellipse>
          </symbol>
        )
      },
      link: {
        typeText: 'Linkki',
        shapeId: '#link',
        shape: (
          <symbol viewBox="-27 0 154 154" id="link" width="154" height="154">
            <rect transform="translate(50) rotate(45)" width="109" height="109" />
          </symbol>
        )
      },
      question: {
        typeText: 'Kysymys',
        shapeId: '#question',
        shape: (
          <symbol viewBox="0 0 154 154" width="154" height="154" id="question">
            <rect x="0" y="0" rx="2" ry="2" width="154" height="154" />
          </symbol>
        )
      }
    },
    NodeSubtypes: {},
    EdgeTypes: {
      yes: {  // required to show empty edges
        shapeId: "#yes",
        shape: (
          <symbol viewBox="0 0 50 50" id="yes" key="0">
            <rect transform="rotate(45)" x="27.5" y="-7.5" width="15" height="15" fill="green" />
          </symbol>
        )
      },
      no: {  // required to show empty edges
        shapeId: "#no",
        shape: (
          <symbol viewBox="0 0 50 50" id="no" key="0">
            <circle cx="25" cy="25" r="8" fill="red"> </circle>
          </symbol>
        )
      }
    }
  }
}

export default Config;
