import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';


class ContactSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      selectedIndex: this.props.selectedIndex ? this.props.selectedIndex : 0,
      onClick: this.props.onClick
    }

    this.anchorRef = React.createRef()

    this.handleMenuItemClick = this.handleMenuItemClick.bind(this)
    this.handleToggle = this.handleToggle.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleMenuItemClick(event, index, id) {
    this.state.onClick(index, id)
    this.setState({ selectedIndex: index, open: false })
  }

  handleToggle() {
    this.setState({ open: !this.state.open });
  }

  handleClose(event) {
    if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  }

  render() {
    const { options } = this.props;

    return (
      <Grid container>
        <Grid item xs={12} align="center">
          <ButtonGroup variant="contained" color="primary" ref={this.anchorRef} aria-label="split button">
            <Button onClick={event => this.handleMenuItemClick(event, this.state.selectedIndex)}>{options[this.state.selectedIndex]['name']}</Button>
            <Button
              color="primary"
              size="small"
              aria-owns={this.state.open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={this.handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper open={this.state.open} anchorEl={this.anchorRef.current} transition disablePortal style={{zIndex:1}}>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper id="menu-list-grow">
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList>
                      {options.map((option, index) => (
                        <MenuItem
                          key={option.id}
                          selected={index === this.state.selectedIndex}
                          onClick={event => this.handleMenuItemClick(event, index, option.id)}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(ContactSelector)
