import React, { Component } from 'react';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import NodeSelector from "./NodeSelector";


class BuilderForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: 0
    };

    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(selectedIndex) {
    this.props.clearFields();
    this.setState({ selectedIndex })
  }

  render() {
    const {
      graph,
      onChange,
      handleTreeNameChange,
      question,
      answer,
      link,
      saveFields,
      getGraphs,
      getContacts,
      openGraph,
      saveGraph,
      clearGraph,
      botPreview
    } = this.props;

    const options = ['Uusi kysymys', 'Uusi vastaus', 'Uusi linkki'];

    return (
      <div>
        <div style={{ position: 'absolute', top: 70, left: 0, width: '200px'}}>

          <div style={{position:'relative', top: 0, left: 10, marginBottom: '40px'}}>
            <h3 style={{ marginTop: '10px', marginBottom: '0px' }}>Puun tiedot</h3>
            <TextField
              id="tree-id"
              label="Puun tunniste"
              value={graph.treeName}
              onChange={handleTreeNameChange}
              margin="normal"
            />
            <div>
              <FormControl>
                <InputLabel htmlFor="phase">Puun vaihe</InputLabel>
                <Select
                  value={graph.phase}
                  onChange={(e) => onChange({ graph: {...graph, phase: e.target.value } })}
                  inputProps={{
                    name: 'phase',
                    id: 'phase',
                  }}
                >
                  <MenuItem value={'planning'}>Suunnittelu</MenuItem>
                  <MenuItem value={'bidding'}>Viimeistely</MenuItem>
                  <MenuItem value={'purchase'}>Hankintapäätös</MenuItem>
                  <MenuItem value={'contract'}>Sopimuskausi</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="contact">Yhteyshenkilö</InputLabel>
                <Select
                  value={graph.contact}
                  onChange={(e) => onChange({ graph: {...graph, contact: e.target.value } })}
                  inputProps={{
                    name: 'contact',
                    id: 'contact',
                  }}
                >
                  {getContacts()}
                </Select>
              </FormControl>
            </div>

          </div>
          <div style={{position:'relative', left: 10, marginBottom: '20px'}}>
            <h3 style={{ marginTop: '10px', marginBottom: '0px' }}>Lisää noodi</h3>
            <div style={{ marginBottom: '100px' }}>
              <NodeSelector options={options} selectedIndex={this.state.selectedIndex} onClick={this.handleSelect}  />
            </div>
            <div style={{ display: this.state.selectedIndex === 0 ? 'block' : 'none' }}>
              <TextField
                id="question-fi"
                label="Kysymys FI"
                name="question.fi.title"
                value={question.fi.title}
                onChange={e => saveFields(e, 'question.fi.title')}
                margin="normal"
              />
              <TextField
                id="detail-fi"
                label="Selite FI"
                value={question.fi.detail}
                onChange={e => saveFields(e, 'question.fi.detail')}
                margin="normal"
              />
              <TextField
                id="question-en"
                label="Kysymys EN"
                value={question.en.title}
                onChange={e => saveFields(e, 'question.en.title')}
                margin="normal"
              />
              <TextField
                id="detail-en"
                label="Selite EN"
                value={question.en.detail}
                onChange={e => saveFields(e, 'question.en.detail')}
                margin="normal"
              />
            </div>
            <div style={{ display: this.state.selectedIndex === 1 ? 'block' : 'none' }}>
              <TextField
                id="answer-fi"
                label="Vastaus FI"
                // className={classes.textField}
                value={answer.fi.title}
                onChange={e => saveFields(e, 'answer.fi.title')}
                margin="normal"
              />
              <TextField
                id="answer-en"
                label="Vastaus EN"
                // className={classes.textField}
                value={answer.en.title}
                onChange={e => saveFields(e, 'answer.en.title')}
                margin="normal"
              />
            </div>
            <div style={{ display: this.state.selectedIndex === 2 ? 'block' : 'none' }}>
              <TextField
                id="link-fi"
                label="Linkkivastaus FI"
                // className={classes.textField}
                value={link.fi.title}
                onChange={e => saveFields(e, 'link.fi.title')}
                margin="normal"
              />
              <TextField
                id="link-en"
                label="Linkkivastaus EN"
                // className={classes.textField}
                value={link.en.title}
                onChange={e => saveFields(e, 'link.en.title')}
                margin="normal"
              />
              <TextField
                id="link-name"
                label="Linkattavan puun nimi"
                // className={classes.textField}
                value={link.link}
                onChange={e => saveFields(e, 'link.link')}
                margin="normal"
              />
              <FormControl>
                <InputLabel htmlFor="linkedGraph">Linkattava puu</InputLabel>
                <Select
                  value={link.link}
                  onChange={e => saveFields(e, 'link.link')}
                  inputProps={{
                    name: 'linkedGraph',
                    id: 'linkedGraph',
                  }}
                >
                  {getGraphs()}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div style={{ position:'absolute', top: 70, right: 20, width: '400px', zIndex: 1 }}>
          <div style={{ marginBottom: 20 }}>
            <div>
              <FormControl>
                <InputLabel htmlFor="selectedGraph">Puu</InputLabel>
                <Select
                  value={graph.uuid}
                  onChange={(e) => openGraph(e.target.value)}
                  inputProps={{
                    name: 'selectedGraph',
                    id: 'selectedGraph',
                  }}
                >
                  {getGraphs()}
                </Select>
              </FormControl>
            </div>
          </div>
          <div style={{ marginBottom: 20 }}>
            <Button variant="contained" onClick={saveGraph}>Tallenna puu</Button>
            <Button variant="contained" color="secondary" onClick={clearGraph}>Aloita uusi puu</Button>
          </div>
          <Button variant="contained" onClick={botPreview}>Testaa botissa</Button>
        </div>
      </div>
    );
  }
}

export default BuilderForm;
