import React, { Component } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import Navigation from '../Navigation';
import { withAuthentication } from '../Session';

import Graph from "../Builder";
import ContactView from "../Contacts";
import LoginView from "../Login";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <Navigation />
          <Switch>
            <Route exact path="/graph" component={Graph} />
            <Route exact path="/login" component={LoginView} />
            <Route exact path="/contacts" component={ContactView} />
            <Redirect from='*' to='/graph' />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}


export default withAuthentication(App);
