import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { compose } from 'recompose';
import uuid4 from 'uuid4';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';

import ContactForm from "./ContactForm";
import ContactSelector from "./ContactSelector";

class Contacts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contacts: [],
      selectedIndex: 0,
      editContact: this.getContact('new-contact')
    }

    this.db = this.props.firebase.db;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.updateFields = this.updateFields.bind(this);
    this.getContact = this.getContact.bind(this);
  }

  getContact(id) {
    return id === 'new-contact' ? {
      name: '',
      email: '',
      phone: '',
      titles: '',
      areaOfExpertise: '',
      imageUrl: ''
    } : this.state.contacts[id]
  }

  componentDidMount() {
    // Load contacts from database and set to state
    this.db.ref('/contacts').once('value').then((snap) => {
      const contacts = snap.val()
      this.setState({ contacts })
    })
  }

  handleSubmit(event) {
    event.preventDefault();
    const { name, email, phone, titles, areaOfExpertise, imageUrl } = event.target.elements;

    let uuid = this.state.editContact.uuid ? this.state.editContact.uuid : uuid4();
    this.db.ref('contacts/' + uuid).set({
      name: name.value,
      email: email.value,
      phone: phone.value,
      imageUrl: imageUrl.value,
      areaOfExpertise: areaOfExpertise.value,
      uuid,
      titles: titles.value
    }, function(error) {
      if (error) {
        console.error('firebase error', error)
      }
    }).then(() => {
      window.location.reload();
    });
  }

  handleSelect(selectedIndex, id) {
    const editContact = this.getContact(id);
    this.setState({ selectedIndex, editContact })
  }

  logOut = () => this.props.firebase.doSignOut();

  updateFields(event, field) {
    event.preventDefault();
    const editContact = this.state.editContact;
    editContact[field] = event.target.value
    this.setState({ editContact })
  }

  render() {
    const contacts = this.state.contacts;
    const show = contacts.length === 0 ? false : true;

    let options = Object.keys(contacts).map(contact => {
      return { name: contacts[contact].name, id: contacts[contact].uuid }
    });
    options.unshift({ name: 'Luo uusi yhteyshenkilö', id: 'new-contact' });

    return (
      <div>
        { show ?
          <div style={{width: '100%'}}>
            <ContactSelector options={options} selectedIndex={this.state.selectedIndex} onClick={this.handleSelect}  />
            <ContactForm title={this.state.selectedIndex === 0 ? 'Luo uusi yhteyshenkilö' : 'Muokkaa yhteyshenkilötietoja'} onSubmit={this.handleSubmit} updateFields={this.updateFields} contact={this.state.editContact} />
          </div> : ''
         }

      </div>
    );
  }
}

const authCondition = authUser => !!authUser;
const ContactView = compose(
  withRouter,
  withFirebase
)(Contacts)

export default withAuthorization(authCondition)(ContactView);
